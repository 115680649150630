.TreatmentTypeSelector__color {
  border-radius: 50%;
  height: 20px;
  margin-right: 10px;
  width: 20px;
}

.TreatmentTypeSelector__color--blue {
  background-color: blue;
  border-color: blue;
}

.TreatmentTypeSelector__color--red {
  background-color: red;
  border-color: red;
}

.TreatmentTypeSelector__color--green {
  background-color: green;
  border-color: green;
}

.TreatmentTypeSelector__color--yellow {
  background-color: yellow;
  border-color: yellow;
}

.TreatmentTypeSelector__content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.TreatmentTypeSelector__content i {
  color: green;
  margin-left: 20px;
}

.TreatmentTypeSelector__treatment-type {
  cursor: pointer;
  display: flex !important;
  flex-direction: row;
  /* justify-content: space-evenly; */
}

.TreatmentTypeSelector__treatment-type--selected {
  color: black !important;
}

.TreatmentTypeSelector__treatment-type-wrapper {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  padding-top: 6px;
  width: 100%;
}

.TreatmentTypeSelector__selected-icon {

}
