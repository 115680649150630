.Image {
  margin: 5px !important;
}

.Image__description {
  max-width: 65%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image-wrapper {
  margin: 10px 20px;
}

.image-wrapper .image {
  display: flex;
  justify-content: center;
}

.image-wrapper img {
  cursor: pointer;
}
