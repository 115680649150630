.PatientDetail__edit-button-wrapper {
    display: flex;
    justify-content: center;
    margin: 20px;
}

.file-uploader-wrapper {
  padding: 20px 0;
}

.images-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
