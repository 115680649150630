.PatientsTable__table-header > p {
    margin: 0;
}

.PatientsTable__table-header--sortable {
    cursor: pointer !important;
}

.PatientsTable__table-header--sortable > p {
    color: #4183c4;
}
