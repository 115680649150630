.ResetPassword {
  background-color: #DADADA;
  height: inherit;
  margin: 0 !important;
  width: inherit;
}

.ResetPassword__wrapper {
  width: 400px;
}
