.Sidebar {
  min-width: 150px !important;
}

.Sidebar--hidden {
  display: none !important;
}

.Sidebar--visible {
  display: block !important;
}

.BurgerButton {
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 40px !important;
  position: absolute;
  right: 0;
}

.Sidebar > .item {
  align-items: center;
  display: flex !important;
  justify-content: center;
  width: 100%;
}

.Sidebar__logo-wrapper > img {
  width: 40px !important;
}

.Sidebar > .item--bottom {
  bottom: 0;
  position: absolute !important;
}

.Sidebar > .item--bottom > img {
  margin: 5px !important;
  width: 100px !important;
}

@media screen and (min-width: 830px) {
  .BurgerButton {
    display: none;
  }

  .Sidebar {
    display: block !important;
    width: 15% !important;
  }

  .Sidebar__logo-wrapper > img {
    width: 55px !important;
  }
}
