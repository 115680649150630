.Odontogram {
    padding: 10px 25px 0 25px;
}

.Odontogram__wrapper {
    display: flex;
    justify-content: center;
}

.Odontogram__wrapper > svg {
    /* TODO: fix this */
    overflow: visible;
}

.Odontogram__footer {
    display: flex;
    justify-content: space-between;
}

.Odontogram__date {
    color: rgba(0,0,0,.4);
    display: flex;
    font-size: 13px;
    justify-content: flex-end;
    margin-top: 10px;
}

