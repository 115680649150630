.ExtraItem {
    display: flex;
    justify-content: center;
    width: 100%;
}

.ExtraItem__wrapper {
    align-items: center;
    display: flex;
}
