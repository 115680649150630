.BaseView {
  padding-top: 20px;
}

.BaseView .pusher {
  transform: none !important;
}

@media screen and (min-width: 830px) {
  .BaseView .pusher {
    transform: translate3d(10%, 0, 0) !important;
  }

  .BaseView .BaseView__content {
    max-width: 85% !important;
  }
}
