.Modal {
  align-items: center;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.Modal__container {
  align-items: center;
  display: flex !important;
  height: 100%;
  max-height: 90%;
}

.Modal__image-wrapper {
  max-height: 100%;
}

.Modal__image {
  max-width: 100%;
}

.Modal--hidden {
  display: none;
}
