.Observations__no-observations-wrapper {
    display: flex;
}

.Observations__add-observation-button {
    margin-left: 10px !important;
}

.Observation__observation-input {
    margin-top: 35px;
    width: 100%;
}

.Observation__wrapper {
  border-bottom: 1px solid #e2e2e;
  font-weight: 300 !important;
}

.Observations__content {
    display: flex !important;
    justify-content: space-between;
}

.Observations__content-list {
  width: 100%;
}

.Observations__content-text {
    display: flex;
    flex: 2;
}

.Observations__content-text-icon {
    margin-right: 10px;
}
