.Extras__extras-list-wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.Extras__extras-container {
  width: 100%;
}

.Extras__extras-wrapper {
  width: 100%;
}

.Extras__extras-item-wrapper {
  display: flex !important;
  max-height: 35px;
}

.Extras__extras-item-image-wrapper {
  width: 20%;
}

.Extras__extras-item-name-wrapper {
  align-items: center;
  color: unset;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Extras__extra-selected {
  color: #008080;
}
